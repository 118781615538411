import React, { useEffect, useState } from 'react';
import Navigation from '../components/Navigation';

import axios from 'axios';
import Loader from '../components/Loader';
import NotFoundComponent from '../components/NotFoundComponent';
import Hero from '../components/Hero';
import Second from '../components/Second';
import Third from '../components/Third';
import Fourth from '../components/Fourth';
import ModalA from '../components/ModalA';
import Footer from '../components/Footer';


const Home=()=> {
  const [loading, setLoading] = useState(true);
  const [modalIsOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const [trackingData, setTrackingData] = useState({
    gclid: null,
    utm_source: null,
    utm_medium: null,
    utm_campaign: null,
    utm_term: null,
  });
  
    useEffect(() => {
      const queryParams = new URLSearchParams(window.location.search);
  
      const gclid = queryParams.get('gclid');
      const utm_source = queryParams.get('utm_source');
      const utm_medium = queryParams.get('utm_medium');
      const utm_campaign = queryParams.get('utm_campaign');
      const utm_term = queryParams.get('utm_term');
  
      setTrackingData({
        gclid: gclid,
        utmSource: utm_source,
        utmMedium: utm_medium,
        utmCampaign: utm_campaign,
        utmTerm: utm_term
      });
  
  
  
    if(gclid){
      axios.get('https://api.socialgamesclubs.com/api/Games') 
      .then(response => {
        setData(response.data);
        setLoading(false); 
        console.log(response.data.isSuccess)
        setError(response.data.isSuccess==false);
      })
      .catch(error => {
        console.error('API çağrısı sırasında bir hata oluştu:', error);
        setLoading(false); 
      });
    }else{
      setLoading(false)
    }
     
    }, []);
  
    if (loading) return <Loader />;
  
    if (error) return <NotFoundComponent trackingData={trackingData} />;
  
  return (
    <div className="home">
      <Navigation/>
      <Hero/>
      <Second/>
      <Third/>
      <Fourth setModalOpen={setModalOpen} />
      {
        modalIsOpen &&(
     <ModalA setModalOpen={setModalOpen} />
        )
      }
      <Footer/>
    </div>
  );
}

export default Home;